(() => {
  const isMobile = () => {
    return !isTablet();
  };

  const isTablet = () => {
    return window.matchMedia("(min-width:48rem)").matches;
  };

  const isDesktop = () => {
    return window.matchMedia("(min-width:64rem)").matches;
  };

  const isTabletOnly = () => {
    return isTablet() && !isDesktop();
  };

  const setGlobalSizes = () => {
    const bodyStyle = document.body.style;

    const clientWidth = document.body.clientWidth;
    bodyStyle.setProperty("--page-width", clientWidth + "px");

    const containerWidth = Math.min(1252, clientWidth - (isMobile() ? 50 : 120));
    bodyStyle.setProperty("--container-width", containerWidth + "px");
  };

  const calculateViewportHeight = () => {
    document.documentElement.style.setProperty("--visual-viewport-height", window.visualViewport?.height + "px");
    document.documentElement.style.setProperty("--viewport-height", window.innerHeight + "px");
    handleScroll();
  };

  let timer: NodeJS.Timeout;

  const calculateIosFixedElementTop = () => {
    const fixedElement = document.getElementById("fixedAnchor")!;
    document.documentElement.style.setProperty("--fixed-element-top", fixedElement.getBoundingClientRect().top + "px");
  };

  const handleScroll = () => {
    clearTimeout(timer);
    timer = setTimeout(calculateIosFixedElementTop, 30);
  };

  addEventListener("resize", () => {
    setGlobalSizes();
  });

  addEventListener("scroll", handleScroll);

  if (window.visualViewport) {
    window.visualViewport.addEventListener("resize", calculateViewportHeight);
  }

  const setBreakpointReference = () => {
    const tabletBreakpoint = window.getComputedStyle(document.body).getPropertyValue("--breakpoint-tablet");
    const desktopBreakpoint = window.getComputedStyle(document.body).getPropertyValue("--breakpoint-desktop");
    const bodyStyle = document.body.style;
    bodyStyle.setProperty("--breakpoint-tablet", tabletBreakpoint);
    bodyStyle.setProperty("--breakpoint-desktop", desktopBreakpoint);
  };
  setBreakpointReference();
  setGlobalSizes();
  calculateViewportHeight();
  calculateIosFixedElementTop();
})();

Element.prototype.observeResize =
  Element.prototype.observeResize ||
  function (this: Element, callback: () => void) {
    if ('ResizeObserver' in window) {
      // create an Observer instance
      const resizeObserver = new ResizeObserver(callback);

      // start observing a DOM node
      resizeObserver.observe(this);
    } else {
      let lastRect = this.getBoundingClientRect();

      setInterval(() => {
        const rect = this.getBoundingClientRect();
        if (Math.floor(rect.width) != Math.floor(lastRect.width) || Math.floor(rect.height) != Math.floor(lastRect.height)) {
          lastRect = rect;
          callback();
        }
      }, 500);
    }
  };
